var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('master-page',{attrs:{"icone":"mdi-layers-triple-outline","titulo":"Serviço","subTitulo":"Lista dos serviços para uso no sistema."}},[_c('v-row',{staticClass:"toolbar pa-2",attrs:{"align":"center"}},[_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-2",attrs:{"fab":"","small":"","depressed":"","color":"primary"},on:{"click":function($event){return _vm.ExportarExcel('tabela')}}},on),[_c('v-icon',[_vm._v("mdi-file-excel-outline")])],1)]}}])},[_c('span',[_vm._v("Exportar (Excel)")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-2",attrs:{"fab":"","small":"","depressed":"","color":"primary","dark":""},on:{"click":function($event){_vm.sheet = true}}},on),[_c('v-icon',[_vm._v("mdi-filter")])],1)]}}])},[_c('span',[_vm._v("Filtrar")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-2",attrs:{"fab":"","small":"","depressed":"","color":"primary"},on:{"click":function($event){return _vm.Atualizar()}}},on),[_c('v-icon',[_vm._v("mdi-refresh")])],1)]}}])},[_c('span',[_vm._v("Atualizar")])]),(_vm.app.role == 'Admin' || _vm.app.role == 'Residuo'|| _vm.app.role == 'ResiduoArquivista')?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-2",attrs:{"fab":"","small":"","depressed":"","color":"primary"},on:{"click":function($event){return _vm.AbrirDialogCadastro()}}},on),[_c('v-icon',[_vm._v("mdi-plus")])],1)]}}],null,false,3425255280)},[_c('span',[_vm._v("Novo")])]):_vm._e()],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"id":"tabela","headers":_vm.headers,"items":_vm.lista,"options":_vm.options,"server-items-length":_vm.totalLista,"loading":_vm.loading,"footer-props":{ showFirstLastPage: true}},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(_vm.app.role == 'Admin' || _vm.app.role == 'Residuo'|| _vm.app.role == 'ResiduoArquivista')?_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"small":""},on:{"click":function($event){return _vm.AbrirDialogCadastro(item)}}},on),[_vm._v("mdi-pencil")])]}}],null,true)},[_c('span',[_vm._v("Editar")])]):_vm._e(),(_vm.app.role == 'Admin' || _vm.app.role == 'Residuo'|| _vm.app.role == 'ResiduoArquivista')?_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"small":""},on:{"click":function($event){return _vm.Excluir(item)}}},on),[_vm._v("mdi-delete")])]}}],null,true)},[_c('span',[_vm._v("Excluir")])]):_vm._e()]}},{key:"item.preco",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.preco.toMoeda())+" ")]}},{key:"item.ativo",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.ativo.toSimNao())+" ")]}}],null,true)}),_c('cadastro-servico',{attrs:{"item":_vm.item},on:{"fechou":function($event){_vm.dialogCadastro = false},"salvou":function($event){return _vm.Atualizar()}},model:{value:(_vm.dialogCadastro),callback:function ($$v) {_vm.dialogCadastro=$$v},expression:"dialogCadastro"}}),_c('v-bottom-sheet',{model:{value:(_vm.sheet),callback:function ($$v) {_vm.sheet=$$v},expression:"sheet"}},[_c('v-sheet',[_c('v-card',[_c('v-card-title',[_c('v-icon',{attrs:{"size":"30","color":"primary"}},[_vm._v("mdi-filter")]),_c('div',{staticClass:"pl-3"},[_c('h4',{staticClass:"font-weight-medium"},[_vm._v("Filtros")])])],1),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"5"}},[_c('v-text-field',{attrs:{"type":"text","label":"Nome do Serviço","counter":100,"maxlength":"100","dense":"","outlined":""},model:{value:(_vm.filtro.nome),callback:function ($$v) {_vm.$set(_vm.filtro, "nome", $$v)},expression:"filtro.nome"}})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-select',{attrs:{"items":_vm.grupos,"label":"Grupo","item-value":"id","item-text":"nome","dense":"","outlined":""},model:{value:(_vm.filtro.grupoId),callback:function ($$v) {_vm.$set(_vm.filtro, "grupoId", $$v)},expression:"filtro.grupoId"}})],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-select',{attrs:{"items":_vm.situacaoServico,"label":"Situação","item-value":"valor","item-text":"nome","dense":"","outlined":""},model:{value:(_vm.filtro.ativo),callback:function ($$v) {_vm.$set(_vm.filtro, "ativo", $$v)},expression:"filtro.ativo"}})],1)],1),_c('v-row',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.Atualizar(true)}}},[_vm._v("Consultar")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }