































































































import { Vue, Component, Watch } from 'vue-property-decorator';
import {GrupoServicoService, ServicoService} from '@/core/services/residuo';
import {Servico} from '@/core/models/residuo';
import { PageBase } from '@/core/models/shared';

@Component
export default class ListaServico extends PageBase {
  loading: boolean = false;
  dialogCadastro: boolean = false;
  totalLista: number = 0;
  lista: any[] = [];
  options: any = {
    itemsPerPage: 15
  };
  headers: any[] = [
    { text: '',value:'actions' ,sortable: false },
    { text: 'Código', value: 'codigo' },
    { text: 'Nome', value: 'nome' },
    { text: 'Grupo', value: 'grupo.nome' },
    { text: 'Preço', value: 'preco' },
    { text: 'Ativo', value: 'ativo' },
  ];
  item = new Servico();
  service = new ServicoService();

  isServicoLoading: boolean = false;
  onSearchReceptor: any = null;

  grupos: any[] = [];
  grupoService = new GrupoServicoService();

  situacaoServico: any = [];  
  sheet: boolean = false;
  filtro: any = {
    nome: '',
    grupoId: 0,
    ativo: null
  }

  CarregaGrupoServico(){
    this.grupoService.ListarTudo()
      .then(
        res=>{
          this.grupos = res.data.items;
          this.grupos.unshift({
              id: 0,
              nome: 'Todos'
          });;
        },
        err=>{
          if (!err.response) {
          this.$swal("Aviso", "Não foi possível acessar a API", "error");
          } else {
          this.$swal("Aviso", err.response.data, "error");
          }
        }
      )
  }

  @Watch('options', { deep: true })
  Atualizar(isFiltro: boolean = false){

    if (isFiltro == true) {
      this.options.page = 1;
    }

    const { page, itemsPerPage, sortBy, sortDesc, search, columns } = this.options;
    this.loading = true;
    this.sheet = false;
    
    this.service.Listar(page, itemsPerPage, sortBy, sortDesc, null, this.headers, this.service.MontaFiltro(this.filtro), 'Grupo').then(
      res => {
        this.lista = res.data.items;
        this.totalLista = res.data.count;
      },
      err => {
        if (!err.response) {
          this.$swal("Aviso", "Não foi possível acessar a API", "error");
        } else {
          this.$swal("Aviso", err.response.data, "error");
        }
      }
    )
    .finally(() => (this.loading = false));
  }

  CarregarSituacao()
  {
    this.situacaoServico.push(
      { nome: 'Todos', valor: null },
      { nome: 'Ativo', valor: true },
      { nome: 'Inativo', valor: false }
    );
  }

  mounted(){
    this.CarregarSituacao();
    this.CarregaGrupoServico();
  }

  AbrirDialogCadastro(item?: Servico){
    if(item){
      this.service.ObterPorId(item.id, "Grupo").then(
        res=>{
          this.item =  new Servico(res.data);
          this.dialogCadastro = true;
        },
        err => {
          if (!err.response) {
            this.$swal("Aviso", "Não foi possível acessar a API", "error");
          } else {
            this.$swal("Aviso", err.response.data, "error");
          }
        }
      )      
    }
    else{
      this.item = new Servico();
      this.item.empresaId = this.app.empresaId;
      this.dialogCadastro = true;
    }
  }

  Excluir(item: Servico){
    this.$swal({
      title: 'Atenção!',
      text: 'Tem certeza que deseja excluir o registro atual?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
      showCloseButton: true,
      showLoaderOnConfirm: true,
      preConfirm: () => {
          return this.service.Excluir(item.id)
                  .then(res => {
                      if (res.status == 200){
                          return res.data;
                      }
                  },
                  err => {
                    if (err.response.status == 403){
                      this.$swal("Aviso", "Você não tem permissão para essa funcionalidade!", "warning");                
                    }
                    else{
                      this.$swal('Aviso', err.response.data, 'error')
                    }
                  }
              )
          },
      // @ts-ignore: Unreachable code error
      allowOutsideClick: () => !this.$swal.isLoading()
      })
      .then((result) => {
          if(result.value) {
              this.$swal("Aviso", result.value, "success");
              this.Atualizar();
          }
      })
  }
}

